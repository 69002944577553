/**
 * @file legacy-login.component.ts
 */
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-legacy-login',
  templateUrl: './legacy-login.component.html',
  styleUrls: ['./legacy-login.component.scss']
})
export class LegacyLoginComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  register(): void {
    window.location.href = 'https://ehmpb2cdev.b2clogin.com/ehmpb2cdev.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_su&client_id=5dc671b0-988f-4abd-a1eb-b2bdcd59042c&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fportal-dev.myempirxhealth.com&scope=openid&response_type=id_token&prompt=login&email=test@test.com&dob=12-12-1997&fname=test&lname=test&cardid=12345&contact=7276279026';
  }

  login(): void { }

}
