/**
 * @file error-manager.service.ts
 * @description this file implements method to throw error
 */
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { AppConstants } from 'src/app/app.constants';
import { ErrorType, ErrorObject } from '../error.interface';

@Injectable({
  providedIn: 'root'
})
export class ErrorManagerService {
  private static readonly className = 'ErrorManagerService';

  private static readonly ERROR_DEFAULT_MESSAGES: { [key: string]: string; } = {
    [ErrorType.ERROR_GENERIC]: AppConstants.ERROR.MESSAGES.GENERIC,
    [ErrorType.ERROR_FATAL]: AppConstants.ERROR.MESSAGES.FATAL
  };
  private readonly errorObservable: ReplaySubject<ErrorObject> = new ReplaySubject<ErrorObject>(0);

  /**
   * @description this method used to throw error
   * @param  ErrorObject error
   */
  public throwError(error: ErrorObject): void {
    const message = error.message ? error.message : ErrorManagerService.ERROR_DEFAULT_MESSAGES[ErrorType.ERROR_GENERIC];
    const type = error.type ? error.type : ErrorType.ERROR_GENERIC;
    this.errorObservable.next({ ...error, message, type });
  }

  /**
   * @returns ReplaySubject
   */
  public getErrorObservable(): ReplaySubject<ErrorObject> {
    return this.errorObservable;
  }
}
