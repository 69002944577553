/**
 * @file log.service.ts
 * @description handles logs functionality
 */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  private static readonly className = 'LogService';

  private isEnabled = false;

  constructor() { }

  /**
   * @description check if log enabled
   */
  public isLogEnabled(): boolean {
    return this.isEnabled;
  }

  /**
   * @description Enable Logging
   */
  public enableLogging(): void {
    this.isEnabled = true;
  }

  /**
   * @param  string message
   * @returns void
   */
  public log(message: string): void {
    if (this.isEnabled) {
      console.log(message);
    }
  }

  /**
   * @param  string message
   * @returns void
   */
  public error(message: string): void {
    if (this.isEnabled) {
      console.error(message);
    }
  }

  /**
   * @param  string message
   * @returns void
   */
  public warn(message: string): void {
    if (this.isEnabled) {
      console.warn(message);
    }
  }

}
