/**
 * @file error.interface.ts
 */
export enum ErrorType {
  ERROR_GENERIC = 0,
  ERROR_FATAL = 1
}

export interface ErrorObject {
  message: string;
  type?: ErrorType;
  url?: string;
  component?: string;
  title?: string;
}
