/**
 * @file custom-validator.service.ts
 */
import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CustomValidatorService {

  static emailRegEx: RegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  static passWordRegEx: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  static atLeastOneUpperCase: RegExp = /(?=.*[A-Z])/;
  static atLeastOneDigit: RegExp = /(?=.*\d)/;
  static atLeastOneSpecialChar: RegExp = /(?=.*[!@#$%^&*])/;

  constructor() { }
  /**
   * @param  number min
   * @param  number max
   * @returns ValidatorFn
   */
  public static ageRangeValidator(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== undefined && (isNaN(control.value) || control.value < min || control.value > max)) {
        return { ageRange: true };
      }
      return null;
    };
  }


  /**
   * @description pass the name of the control to match to with current control
   * @param  matchTo matchTo
   */
  public static matchValues(matchTo: string): (abstractControl: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
        !!control.parent.value &&
        control.value === control.parent.get(matchTo)?.value
        ? null
        : { isNotMatching: true };
    };
  }
  /**
   * @description checks if password follow all test cases
   * @param  AbstractControl control
   * @returns boolean
   */
  public static passStrength(control: AbstractControl): { strength: { [key: string]: boolean } } | null {
    const strength: { [key: string]: boolean } = {
      atLeastOneUpperCase: !CustomValidatorService.atLeastOneUpperCase.test(control.value),
      atLeastOneDigit: !CustomValidatorService.atLeastOneDigit.test(control.value),
      atLeastOneSpecialChar: !CustomValidatorService.atLeastOneSpecialChar.test(control.value),
      length: !(control.value?.length <= 16 && control.value?.length >= 8)
    };
    if (
      CustomValidatorService.atLeastOneUpperCase.test(control.value) &&
      CustomValidatorService.atLeastOneDigit.test(control.value) &&
      CustomValidatorService.atLeastOneSpecialChar.test(control.value) &&
      control.value?.length <= 16 && control.value?.length >= 8
    ) {
      return null;
    }
    return { strength };
  }
  /**
   * @description returns meter value from 1 to 4
   * @param  string password
   * @returns number
   */
  public static getMeter(password: string): number {

    const cases: null[] = [];
    if (CustomValidatorService.atLeastOneUpperCase.test(password)) {
      cases.push(null);
    }
    if (CustomValidatorService.atLeastOneDigit.test(password)) {
      cases.push(null);
    }
    if (CustomValidatorService.atLeastOneSpecialChar.test(password)) {
      cases.push(null);
    }
    if (password?.length >= 8) {
      cases.push(null);
    }
    return cases.length;
  }

}
