import { Component, OnInit } from '@angular/core';
import { AppCenterAnalytics } from '../services/app-insights/app-insights';
import { AppInsightsService } from '../services/app-insights/app-insights.service';
import { MultilingualService } from '../core/services/multilingual/multilingual.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(
    public readonly multilingualService: MultilingualService,
    private readonly appInsightsService: AppInsightsService
  ) { }

  ngOnInit(): void {
    this.appInsightsService.trackPageView(AppCenterAnalytics.PRIVACY_POLICY_VIEWED);
  }

}
