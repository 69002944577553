/**
 * @file error-handler.service.ts
 * @description handles error by showing Error Dialog
 */
import { Injectable } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

import { ErrorManagerService, ErrorObject } from '../../core/services/error';
import { ErrorDialogComponent } from '../../core/components/error/error-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  private static readonly className = 'ErrorHandlerService';
  public errorDialogRef!: MatDialogRef<ErrorDialogComponent>;

  /**
   * @param  ErrorManagerService error manager service
   * @param  MatDialog Material Dialog whish shows error
   */
  constructor(
    private readonly errorManager: ErrorManagerService,
    public dialog: MatDialog
  ) {
    this.errorManager.getErrorObservable()
      .subscribe((error: ErrorObject) => {
        this.handleError(error);
      });
  }

  /**
   * @param  ErrorObject error object contains title and message
   * @returns void
   */
  private handleError(error: ErrorObject): void {
    this.errorDialogRef = this.dialog.open(ErrorDialogComponent, {
      data: {
        title: error.title,
        message: error.message
      }
    });
  }


  public closeErrorDialog(): void {
    if (this.errorDialogRef) {
      this.errorDialogRef.close();
    }
  }
}
