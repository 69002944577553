/**
 * @file error-dialog.component.ts
 * @description A component to show error dialog
 */
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ErrorObject } from '../../services/error';

@Component({
  selector: 'app-error-dialog',
  templateUrl: 'error-dialog.html'
})
export class ErrorDialogComponent implements OnInit {
  private static readonly className = 'ErrorDialogComponent';

  public title ?= '';
  public message = '';

  /**
   * @param  MatDialogRef dialogRef
   * @param  ErrorObject dialogData
   */
  constructor(
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: ErrorObject
  ) { }

  public ngOnInit(): void {
    this.title = this.dialogData.title;
    this.message = this.dialogData.message;
  }
}
