/**
 * @file terms-of-use.component.ts
 */
import { Component, OnInit } from '@angular/core';
import { AppCenterAnalytics } from '../services/app-insights/app-insights';
import { AppInsightsService } from '../services/app-insights/app-insights.service';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {

  constructor(
    private readonly appInsightsService: AppInsightsService
  ) { }

  ngOnInit(): void {
    this.appInsightsService.trackPageView(AppCenterAnalytics.TERMS_OF_USE_VIEWED);
  }

}
