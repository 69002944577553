/**
 * @file http.service.ts
 * @description this file implements http methods from HttpClient
 */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { RequestOptions } from 'src/app/interfaces';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private static readonly className = 'HttpService';

  /**
   * @param  HttpClient http
   */
  constructor(private readonly http: HttpClient) { }

  private readonly apiBaseUrl: string = environment.hostURL;

  /**
   * @param  string path
   * @param  RequestOptions params?
   * @returns Observable
   */
  public get<T>(path: string, params?: RequestOptions): Observable<T> {
    return this.http.get<T>(`${this.apiBaseUrl}${path}`, params).pipe(take(1));
  }

  /**
   * @param  string path
   * @param  object body
   * @param  RequestOptions options?
   * @returns Observable
   */
  public put<T>(path: string, body: object = {}, options?: RequestOptions): Observable<T> {
    return this.http.put<T>(`${this.apiBaseUrl}${path}`, body, options).pipe(take(1));
  }

  /**
   * @param  string path
   * @param  object body
   * @param  RequestOptions options?
   * @returns Observable
   */
  public post<T>(path: string, body: object = {}, options?: RequestOptions): Observable<T> {
    return this.http.post<T>(`${this.apiBaseUrl}${path}`, body, options).pipe(take(1));
  }

  /**
   * @param  string path
   * @param  RequestOptions options?
   * @returns Observable
   */
  public delete<T>(path: string, options?: RequestOptions): Observable<T> {
    return this.http.delete<T>(`${this.apiBaseUrl}${path}`, options).pipe(take(1));
  }
}
