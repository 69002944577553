/**
 * @file profiler.interceptor.ts
 * @description this file implements interceptor for profiling http req/res
 */
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import { LogService } from '../../core/services/log/log.service';

@Injectable({
  providedIn: 'root'
})
export class ProfilerInterceptor implements HttpInterceptor {
  private static readonly className = 'ProfilerInterceptor';

  constructor(private readonly logService: LogService) { }

  /**
   * @description Check http request status and its time
   * @param  HttpRequest angular common http request
   * @param  HttpHandler next HttpHandler
   */
  public intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    const startTime = Date.now();
    let status = '';

    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<T>) => status = (event instanceof HttpResponse) ? 'SUCCEEDED' : 'FAILED',
        (error) => status = 'FAILED'
      ),
      finalize(() => {
        const responseTime = Date.now() - startTime;
        const message = `${request.method} ${request.urlWithParams} ${status} in ${responseTime} ms`;
        this.logService.log(message);
      })
    );
  }
}
