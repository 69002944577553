import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sso-error',
  templateUrl: './sso-error.component.html',
  styleUrls: ['./sso-error.component.scss']
})
export class SsoErrorComponent implements OnInit {

  error = '';

  constructor(
    private readonly active: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.error = new URLSearchParams(this.active.snapshot.fragment ? this.active.snapshot.fragment : '').get('userMessage') || '';
  }

}
