import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, Routes, TitleStrategy } from '@angular/router';
import { MultilingualService } from '../core/services/multilingual/multilingual.service';


@Injectable()
export class CustomTitleStrategy extends TitleStrategy {
    private mapURL: Map<string, string> = new Map([
        ['home', 'LABEL_BOTTOM_TABS_HOME'],
        ['orders', 'LABEL_BOTTOM_TABS_ORDERS'],
        ['settings/notifications', 'LABEL_NOTIFICATIONS'],
        ['settings/address', 'TITLE_SETTINGS_SHIPPING_ADDRESS'],
        ['settings/payment', 'LABEL_MORE_SETTINGS_PAYMENT_METHODS'],
        ['prescriptions', 'LABEL_BOTTOM_TABS_PRESCRIPTIONS'],
        ['drug', 'LABEL_BOTTOM_TABS_DRUG_SEARCH'],
        ['claims', 'LABEL_BOTTOM_TABS_PHARMACY_CLAIMS'],
        ['find-pharmacy', 'LABEL_BOTTOM_TABS_PHARMACY_SEARCH'],
        ['clinical-reviews', 'LABEL_MORE_CLINICAL_REVIEW'],
        ['membership', 'LABEL_BOTTOM_TABS_PHARMACY_MEMBERSHIP'],
        ['benefits', 'LABEL_BOTTOM_TABS_PHARMACY_BENEFITS'],
        ['forms', 'LABEL_FORMS'],
        ['cart', 'LABEL_CART_HOME_CART_ITEMS'],
        ['phi-settings','LABEL_PHI_SETTINGS'],

        ['help/tpa-faq','LABEL_FAQS'],
        ['help/privacy-policy','LABEL_MORE_PRIVACY_POLICY'],
        ['help/terms-of-use','MENU_TERMS_OF_USE'],
        ['help/support','LABEL_MORE_CUSTOMER_SUPPORT'],

        ['alternate-therapy', 'LABEL_MORE_ALTERNATE_THERAPY'],

        ['guest-login', ''],
        ['terms-of-use', ''],
        ['privacy-policy', ''],
        ['legacy-login', ''],
        ['pre-complete-profile', ''],
        ['complete-profile', ''],
        ['forgot-username', '']
    ]);
    private appName: string = 'Member Portal';


    constructor(private readonly title: Title, private multiLang: MultilingualService) {
        super();
    }

    override updateTitle(routerState: RouterStateSnapshot,): void {
        const url = routerState.url;
        let tabTitle: string = '';

        if (this.multiLang.langMap) {
            try {
                for (let itemKey of this.mapURL.keys()) {
                    if (url.includes(itemKey)) {
                        const translationKey = this.mapURL.get(itemKey);
                        if (translationKey) {
                            tabTitle = this.multiLang.langMap[translationKey];
                        }
                        break;
                    }
                }
            } catch (error) {
                tabTitle = '';
            }
            if (tabTitle) {
                this.title.setTitle(`${this.appName} - ${tabTitle}`);
            } else {
                this.title.setTitle(`${this.appName}`);
            }
        } else {
            const title = this.buildTitle(routerState);
            if (title !== undefined) {
                this.title.setTitle(`${this.appName} - ${title}`);
            } else {
                this.title.setTitle(`${this.appName}`);
            }
        }
    }
}