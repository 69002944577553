/**
 * @file main.constants.ts
 */
export const states = [
  { name: 'ALABAMA', value: 'AL', },
  { name: 'ALASKA', value: 'AK', },
  { name: 'AMERICAN SAMOA', value: 'AS', },
  { name: 'ARIZONA', value: 'AZ', },
  { name: 'ARKANSAS', value: 'AR', },
  { name: 'CALIFORNIA', value: 'CA', },
  { name: 'COLORADO', value: 'CO', },
  { name: 'CONNECTICUT', value: 'CT', },
  { name: 'DELAWARE', value: 'DE', },
  { name: 'DISTRICT OF COLUMBIA', value: 'DC', },
  { name: 'FLORIDA', value: 'FL', },
  { name: 'GEORGIA', value: 'GA', },
  { name: 'GUAM', value: 'GU', },
  { name: 'HAWAII', value: 'HI', },
  { name: 'IDAHO', value: 'ID', },
  { name: 'ILLINOIS', value: 'IL', },
  { name: 'INDIANA', value: 'IN', },
  { name: 'IOWA', value: 'IA', },
  { name: 'KANSAS', value: 'KS', },
  { name: 'KENTUCKY', value: 'KY', },
  { name: 'LOUISIANA', value: 'LA', },
  { name: 'MAINE', value: 'ME', },
  { name: 'MARYLAND', value: 'MD', },
  { name: 'MASSACHUSETTS', value: 'MA', },
  { name: 'MICHIGAN', value: 'MI', },
  { name: 'MINNESOTA', value: 'MN', },
  { name: 'MISSISSIPPI', value: 'MS', },
  { name: 'MISSOURI', value: 'MO', },
  { name: 'MONTANA', value: 'MT', },
  { name: 'NEBRASKA', value: 'NE', },
  { name: 'NEVADA', value: 'NV', },
  { name: 'NEW HAMPSHIRE', value: 'NH', },
  { name: 'NEW JERSEY', value: 'NJ', },
  { name: 'NEW MEXICO', value: 'NM', },
  { name: 'NEW YORK', value: 'NY', },
  { name: 'NORTH CAROLINA', value: 'NC', },
  { name: 'NORTH DAKOTA', value: 'ND', },
  { name: 'NORTHERN MARIANA IS', value: 'MP', },
  { name: 'OHIO', value: 'OH', },
  { name: 'OKLAHOMA', value: 'OK', },
  { name: 'OREGON', value: 'OR', },
  { name: 'PENNSYLVANIA', value: 'PA', },
  { name: 'PUERTO RICO', value: 'PR', },
  { name: 'RHODE ISLAND', value: 'RI', },
  { name: 'SOUTH CAROLINA', value: 'SC', },
  { name: 'SOUTH DAKOTA', value: 'SD', },
  { name: 'TENNESSEE', value: 'TN', },
  { name: 'TEXAS', value: 'TX', },
  { name: 'UTAH', value: 'UT', },
  { name: 'VERMONT', value: 'VT', },
  { name: 'VIRGINIA', value: 'VA', },
  { name: 'VIRGIN ISLANDS', value: 'VI', },
  { name: 'WASHINGTON', value: 'WA', },
  { name: 'WEST VIRGINIA', value: 'WV', },
  { name: 'WISCONSIN', value: 'WI', },
  { name: 'WYOMING', value: 'WY', },
];

export const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export const ignoreKeys = ['Escape', 'Control', 'PageUp', 'PageDown', 'Insert', 'PrintScreen', 'CapsLock', 'Shift', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'];

export const emailPattern = '[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}';

export const COMMON_ERROR_MSG = 'Sorry, something went wrong';

export const ERROR_VERIFICATION = 'Sorry, your membership eligibility verification has failed. Please contact the EmpiRx Health customer support at 866-415-0222 or 857-241-2439.';
export const firstLastNamePattern = /^([À-ǿA-Za-z0-9-_'‘’.,~` ]+)$/;

export const CMS_CONFIG_FALLBACK = {
  DISABLE_TERMED_USER_AFTER: 12
};
